import { useCurrentUser } from '@capturi/core'
import {
  ActiveFilterStateKeys,
  FilterPeriodSelectContainer,
  SegmentFilterKeysProvider,
  SingleSegmentContainer,
  useFilterDefinitions,
} from '@capturi/filters'
import { ErrorBoundary, usePageTitle } from '@capturi/react-utils'
import { useSingleUser } from '@capturi/stores'
import {
  HeadingCaption,
  PageHeading,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@capturi/ui-components'
import { Box, Flex } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import UserAvatar from 'components/UserAvatar'
import { UserSelectPopover } from 'components/UserSelectPopover'
import React from 'react'
import { useNavigate } from 'react-router'

import routes from '../routes'
import CoachingComments from './CoachingComments'
import Interaction from './Interaction'
import Overview from './Overview'

type Props = {
  userUid: string
}

const Loader = <Spinner display="block" m="5rem auto" />

const activeStateKeys: ActiveFilterStateKeys = [
  'status',
  'labels',
  'subjects',
  'duration',
  'trackers',
  'notTrackers',
  'sentiment',
  'scores',
]

const CoachingPage: React.FC<Props> = ({ userUid }) => {
  usePageTitle(t`Coaching`)
  const currentUser = useCurrentUser()
  const filterDefinitions = useFilterDefinitions(currentUser)
  const navigate = useNavigate()
  const { profileImage, name, title } = useSingleUser(userUid)
  return (
    <SegmentFilterKeysProvider include={activeStateKeys}>
      <Box>
        <Flex alignItems="center">
          <Flex flex={1} align="center">
            <UserAvatar size="sm" profileImage={profileImage} name={name} />
            <Box ml={2}>
              {currentUser.isAdminOrTeamLead ? (
                <UserSelectPopover
                  onSelectUser={(userid) => navigate(routes.user(userid))}
                  currentSelectedUser={name}
                />
              ) : (
                <PageHeading>{name}</PageHeading>
              )}
              <HeadingCaption color="textMuted">{title}</HeadingCaption>
            </Box>
          </Flex>
          <Box>
            <FilterPeriodSelectContainer />
          </Box>
        </Flex>
      </Box>
      <Box my={6}>
        <SingleSegmentContainer filterDefinitions={filterDefinitions} />
      </Box>
      <Tabs>
        <TabList alignItems="center">
          <Tab data-intercom-target="overview">
            <Trans>Overview</Trans>
          </Tab>
          <Tab data-intercom-target="overview">
            <Trans>Interaction</Trans>
          </Tab>
          <Tab data-intercom-target="goals">
            <Trans>Goals</Trans>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel mt={4} p={0}>
            <ErrorBoundary>
              <React.Suspense fallback={Loader}>
                <Overview userUid={userUid} />
              </React.Suspense>
            </ErrorBoundary>
          </TabPanel>
          <TabPanel mt={4} p={0}>
            <ErrorBoundary>
              <React.Suspense fallback={Loader}>
                <Interaction userUid={userUid} />
              </React.Suspense>
            </ErrorBoundary>
          </TabPanel>
          <TabPanel mt={4} p={0}>
            <ErrorBoundary>
              <React.Suspense fallback={Loader}>
                <CoachingComments userUid={userUid} />
              </React.Suspense>
            </ErrorBoundary>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </SegmentFilterKeysProvider>
  )
}

export default CoachingPage
