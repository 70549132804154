import { BaseTracker } from '@capturi/api-trackers'
import { Role, Speaker, useCurrentUser } from '@capturi/core'
import { useFeatureFlags } from '@capturi/feature-flags'
import {
  toFilterSearchParams,
  useFilterPeriodContext,
  useFirstPhoneSegmentState,
} from '@capturi/filters'
import { Button, Description, PlaySnippetsButton } from '@capturi/ui-components'
import { Tooltip } from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import { useConversationsDrawer } from 'components/ConversationsDrawer'
import { ColumnDefinition, DataGrid } from 'components/DataGrid'
import orderBy from 'lodash/orderBy'
import { getRoutes } from 'pages/analytics'
import UserBreakdownSkeleton from 'pages/analytics/shared/components/UserBreakdownSkeleton'
import { formatValue } from 'pages/analytics/shared/utils'
import React from 'react'
import { MdLaunch } from 'react-icons/md'
import { useNavigate } from 'react-router'

import { CoachingCommentsAnalyticsEvent, logEvent } from './analytics'
import { CoachingTrackerStatistics } from './types'

type Props = {
  trackerStats?: CoachingTrackerStatistics[]
  trackers: BaseTracker[]
  conversationCount?: number
  userUid: string
  isLoading?: boolean
}

type TrackerWithStats = BaseTracker & {
  stats: CoachingTrackerStatistics
}

type CoachingTrackerSegments = {
  segments: {
    label: string
    color: string
    data: TrackerWithStats
  }[]
}

const TrackerHits: React.FC<Props> = ({
  trackerStats = [],
  trackers,
  userUid,
  isLoading,
}) => {
  const currentUser = useCurrentUser()
  const { hideCustomerInsightsFromPlainUsers } = useFeatureFlags()
  const segmentState = useFirstPhoneSegmentState()
  const openConversationsDrawer = useConversationsDrawer()
  const { periodDef } = useFilterPeriodContext()
  const navigate = useNavigate()

  const data = React.useMemo(() => {
    const trackerDict = trackers.reduce<{ [uid: string]: BaseTracker }>(
      (memo, x) => {
        memo[x.uid] = x
        return memo
      },
      {},
    )

    return orderBy(
      trackerStats.map((stats) => {
        const tracker = trackerDict[stats.trackerUid]
        return {
          ...tracker,
          stats,
        }
      }),
      [(x) => x.stats.hitRate, (x) => x.name],
      ['desc', 'asc'],
    )
  }, [trackers, trackerStats])

  const filteredData = React.useMemo(() => {
    if (currentUser.role === Role.user && hideCustomerInsightsFromPlainUsers) {
      return data.filter((x) => {
        // we don't want it to show up if text is active for only Inbound messages
        const hasTextDataToShow =
          x.text &&
          (!x.text.messageFilters?.messageTypes ||
            (x.text.messageFilters?.messageTypes.length === 0 &&
              x.text.messageFilters?.messageTypes.includes('Inbound')))
        const hasSpeechDataToShow =
          x.speech && x.speech.speakerId !== Speaker.Customer
        return hasTextDataToShow || hasSpeechDataToShow
      })
    }
    return data
  }, [data, currentUser, hideCustomerInsightsFromPlainUsers])

  const dataAsSegments = React.useMemo(() => {
    return filteredData.map((x) => ({
      segments: [
        {
          data: x,
          label: 'Tracker',
          color: 'segments.primary',
        },
      ],
    }))
  }, [filteredData])

  const onViewConversations = React.useCallback(
    (trackerUid: string): void => {
      logEvent(CoachingCommentsAnalyticsEvent.ViewTrackerConversations)
      openConversationsDrawer({
        url: 'conversations/list?api-version=3.3&excludeDeleted=false',
        getFilterRequestModel: () =>
          toFilterSearchParams(
            {
              ...segmentState.values,
              userUids: [userUid],
              trackers: [
                {
                  uids: [trackerUid],
                  inverted: false,
                },
              ],
            },
            periodDef,
          ),
      })
    },
    [openConversationsDrawer, periodDef, segmentState.values, userUid],
  )

  const columns = React.useMemo(() => {
    const columns: Array<ColumnDefinition<CoachingTrackerSegments>> = [
      {
        id: 'trackerName',
        type: 'value',
        width: 150,
        getSegmentValue: (d) => (
          <Tooltip label={d.data.description}>{d.data.name}</Tooltip>
        ),
      },
      {
        id: 'hitrate',
        type: 'value',
        alignRight: true,
        getSegmentValue: (s) => {
          const value = s.data.stats.hitRate
          return i18n.number(value, {
            style: 'percent',
            minimumFractionDigits: 0,
            maximumFractionDigits: value < 0.01 ? 1 : 0,
          })
        },
      },
      {
        type: 'progress',
        getSegmentValue: (s) => s.data?.stats.hitRate,
        formatValue: (value) =>
          formatValue(value, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 1,
          }),
        range: [0, 1],
        progressBarHeight: 4,
      },
      {
        type: 'button',
        render: (row) => {
          const { uid, name } = row.segments[0].data
          return (
            <PlaySnippetsButton
              label={t`View conversations for ${name}`}
              onClick={() => onViewConversations(uid)}
              my={2}
            />
          )
        },
      },
    ]

    if (currentUser.isAdminOrTeamLead) {
      columns.push({
        id: 'linkbutton',
        type: 'button',
        render: (row) => {
          const { speech, uid } = row.segments[0].data
          return (
            <Button
              aria-label={t`Go to tracker`}
              size="xs"
              onClick={() => {
                segmentState.setFilterState({
                  userUids: [userUid],
                })
                navigate(
                  getRoutes().trackers.tracker({
                    speaker: speech?.speakerId,
                    trackerUid: uid,
                  }),
                )
              }}
              variant="ghost"
              p={0}
              ml={2}
            >
              <MdLaunch />
            </Button>
          )
        },
      })
    }

    return columns
  }, [
    currentUser.isAdminOrTeamLead,
    navigate,
    onViewConversations,
    segmentState,
    userUid,
  ])

  return (
    <UserBreakdownSkeleton isLoaded={!isLoading}>
      <DataGrid
        data={dataAsSegments}
        columns={columns}
        rowPaddingY={0}
        showRowDivider={false}
      >
        {dataAsSegments.length === 0 && (
          <Description fontSize="xl" textAlign="center" fontWeight="medium">
            <Trans>No data available</Trans>
          </Description>
        )}
      </DataGrid>
    </UserBreakdownSkeleton>
  )
}

export default TrackerHits
