import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import { FormControl, FormLabel, Stack, Textarea } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'

export type CreateCoachingCommentDialogOptions = Record<string, unknown>

type CreateCoachingCommentDialogProps = BaseModalComponentProps<{
  text: string
}>

export const CreateCoachingCommentDialog: React.FC<
  CreateCoachingCommentDialogProps
> = ({ isOpen = false, onClose, onSubmit }) => {
  // Refs
  const formRef = React.useRef<HTMLFormElement>(null)
  const textRef = React.useRef<HTMLTextAreaElement>(null)

  // State
  const [text, setText] = React.useState('')

  const handleSubmitChanges = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    if (formRef.current?.checkValidity() === false) {
      return
    }
    onSubmit?.({
      text,
    })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} initialFocusRef={textRef}>
      <ModalOverlay>
        <ModalContent>
          <form ref={formRef} onSubmit={handleSubmitChanges}>
            <ModalHeader>
              <Trans>Create goal</Trans>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack spacing={4}>
                <FormControl isRequired>
                  <FormLabel htmlFor="commentText">
                    <Trans>Goal</Trans>
                  </FormLabel>
                  <Textarea
                    id="commentText"
                    ref={textRef}
                    placeholder={t`Describe the goal ...`}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                      setText(e.target.value)
                    }
                    value={text}
                    height={40}
                  />
                </FormControl>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Stack direction="row" spacing={4} justify="flex-end">
                <Button secondary type="button" onClick={onClose}>
                  <Trans>Cancel</Trans>
                </Button>
                <Button primary type="submit">
                  <Trans>Create</Trans>
                </Button>
              </Stack>
            </ModalFooter>
          </form>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
