import { useUsers } from '@capturi/stores'
import {
  Button,
  ButtonProps,
  ContentPlaceholder,
  Emoji,
} from '@capturi/ui-components'
import { Box, Flex, IconButton, Stack, Text } from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import UserAvatar from 'components/UserAvatar'
import React from 'react'
import { MdDelete, MdEdit } from 'react-icons/md'

import useCoachingCommentCRUD from './useCoachingCommentCRUD'
import useCoachingComments from './useCoachingComments'
import {
  createComment,
  deleteComment,
  updateComment,
} from './utils/cacheUpdaters'

type Props = {
  userUid: string
}

const AddCommentButton: React.FC<Omit<ButtonProps, 'children'>> = (props) => (
  <Button size="sm" {...props}>
    <Trans>Create goal</Trans>
  </Button>
)

const CoachingComments: React.FC<Props> = ({ userUid }) => {
  const { comments, mutate } = useCoachingComments(userUid)
  const { getUserByUid } = useUsers()

  const actions = useCoachingCommentCRUD({
    onCommentCreated: (comment) =>
      mutate((cache) => createComment(comment, cache), false),
    onCommentUpdated: (comment) =>
      mutate((cache) => updateComment(comment, cache), false),
    onCommentDeleted: (comment) =>
      mutate((cache) => deleteComment(comment, cache), false),
  })

  if (comments.length === 0) {
    return (
      <ContentPlaceholder.Container mt="10vh" size="md">
        <ContentPlaceholder.Heading>
          <Trans>Before you can score, you must have a goal</Trans>
          <Emoji symbol="🎯" label="dart board" fontSize="4xl" pl={2} />
        </ContentPlaceholder.Heading>
        <ContentPlaceholder.Body>
          <Trans>No goals have been created</Trans>
        </ContentPlaceholder.Body>
        <ContentPlaceholder.Footer>
          <AddCommentButton
            primary
            onClick={() => actions.createComment(userUid)}
          />
        </ContentPlaceholder.Footer>
      </ContentPlaceholder.Container>
    )
  }

  return (
    <>
      <Flex justify="flex-end">
        <AddCommentButton
          size="xs"
          onClick={() => actions.createComment(userUid)}
        />
      </Flex>
      <Stack spacing={0}>
        {comments.map((comment) => {
          const { name, profileImage } = getUserByUid(comment.createdByUserUid)
          return (
            <Flex key={comment.uid} wrap="wrap" width="100%" px={4} py={2}>
              <UserAvatar name={name} profileImage={profileImage} size="sm" />
              <Box
                ml={4}
                borderBottom="1px"
                borderBottomColor="border.light"
                flex={1}
              >
                <Flex align="baseline" justify="space-between">
                  <Text fontWeight="medium" textTransform="capitalize">
                    {name}
                  </Text>
                  <Box color="textMuted" fontSize="xs" ml={2}>
                    {i18n.date(comment.createdOn)}
                  </Box>
                </Flex>
                <Stack direction="row" spacing={4} py={2}>
                  <Text
                    flex={1}
                    whiteSpace="pre-wrap"
                    // overflow-wrap: anywhere is not supported by Safari there we add the deprecated word-break: break-word as well
                    wordBreak="break-word"
                    overflowWrap="anywhere"
                  >
                    {comment.text}
                  </Text>
                  <Stack direction="row" spacing={2}>
                    <IconButton
                      aria-label={t`Edit`}
                      icon={<MdEdit />}
                      isRound
                      size="xs"
                      onClick={() => actions.editComment(comment)}
                    />
                    <IconButton
                      aria-label={t`Delete`}
                      icon={<MdDelete />}
                      isRound
                      size="xs"
                      onClick={() => actions.deleteComment(comment)}
                    />
                  </Stack>
                </Stack>
              </Box>
            </Flex>
          )
        })}
      </Stack>
    </>
  )
}

export default CoachingComments
